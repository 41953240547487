import React from 'react';

import Layout from "../layouts/site/layout";

function About() {
  return (
    <div className="App">
       <Layout>
            <div className="px-4 pt-7 pb-44">
                <h2 className="text-red-1000 font-bold text-base">About us</h2>
                <h3 className="text-red-1000 font-bold text-base mt-2">MSI-Quality Forgings</h3>
                <p className="mt-2">Located in Doncaster, England, we specialise in the manufacture of high quality open die hammer forgings for use within many industrial applications.</p>
                <p className="mt-2">The modern and well equipped forge using 21st Century Technology is totally self contained, offering forging, heat treatment, shot blasting, non-destructive testing, CNC machining and Laboratory Testing.</p>
            </div>
       </Layout>
    </div>
  );
}

export default About;
